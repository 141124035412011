<template>
  <div id="app">
  </div>
</template>
<script>

export default {
  name: 'App',
  created() {
    location.assign('https://www.theknot.com/conorandconnie');
  }
}
</script>

<style>
@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
   url(./assets/Oswald/static/Oswald-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Monterey";
  src: local("Monterey"),
   url(./assets/Monterey/Monterey.otf) format("truetype");
}

@font-face {
  font-family: "Garamond";
  src: local("Garamond"),
   url(./assets/Garamond/static/EBGaramond-Italic.ttf) format("truetype");
}

.title {
  background-color: white;
  height: 10%;
  width: 100%;
  position: fixed;
  top: 0
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  height: 100%;
  padding-top: 10%;
}

.faq {
  position: fixed;
  top: 10px;
  right: 20px;
}


a {
  cursor: pointer;
}

#app {
  width: 100%;
  height: 100%;
  color: rgba(0,0,0,.8);
  font-family: Garamond, sans-serif;
  letter-spacing: 1.8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
